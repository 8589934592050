<template>
  <div>
    <header>
      <div class="header-info">
        <div class="wrapper">
          <nav>
            <input id="show-search" type="checkbox" />
            <input id="show-menu" type="checkbox" />
            <div class="content">
              <router-link to="/">
                <img
                  src="
                  /img/HPMainLogo-creators-min.png
                "
                  class="helixpay-main-logo"
                  alt="HelixPay"
                />
              </router-link>
              <ul id="links">
                <li>
                  <a href="#" class="desktop-link">Features </a>
                  <input id="show-features" type="checkbox" />
                  <label for="show-features">Features</label>
                  <ul>
                    <router-link to="/storefronts">
                      <li>
                        <img src="/img/storefront-for-navbar-min.png" alt="" />
                        <a>No-code Storefronts </a>
                      </li>
                    </router-link>
                    <router-link to="/automatednotif">
                      <li>
                        <img
                          src="/img/automated-notif-for-navbar-min.png"
                          alt=""
                        />
                        <a>Automated Notifications </a>
                      </li>
                    </router-link>
                    <router-link to="/analytics">
                      <li>
                        <img src="/img/analytics-for-navbar-min.png" alt="" />
                        <a>Analytics </a>
                      </li>
                    </router-link>
                    <router-link to="/merch">
                      <li>
                        <img src="/img/merch-for-navbar.svg" alt="" />
                        <a>Merch </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
                <li>
                  <a href="#" class="desktop-link">Documentation </a>
                  <input id="show-documentation" type="checkbox" />
                  <label for="show-documentation">Documentation</label>
                  <ul>
                    <!-- <a href="https://helixpay.readme.io/reference/introduction">
                      <li style="align-items: center">
                        <img src="/img/apidocs-for-navbar-min.png" alt="" />
                        <p>API Docs</p>
                      </li>
                    </a> -->
                    <a :href="CONSOLE_DOCS_URL">
                      <li style="align-items: center">
                        <img src="/img/consoledocs-for-navbar-min.png" alt="" />
                        <p>Console Docs</p>
                      </li>
                    </a>
                  </ul>
                </li>
                <router-link to="/pricing">
                  <li><a>Pricing </a></li>
                </router-link>
                <router-link to="/incomecalculator">
                  <li><a>Income Calculator </a></li>
                </router-link>
                <a href="https://helixevents.beehiiv.com/">
                  <li><a>Insights </a></li>
                </a>
                <router-link to="/articles">
                  <li><a>Articles</a></li>
                </router-link>
                <li>
                  <a
                    class="forMobile"
                    onclick="window.open('https://console.helixpay.ph/#/login')"
                  >
                    Log In
                  </a>
                </li>
              </ul>
              <a
                class="forWeb"
                onclick="window.open('https://console.helixpay.ph/#/login')"
              >
                Log In
              </a>
            </div>
            <label for="show-menu" class="menu-icon">
              <i class="fas fa-bars fa-1x" style="color: #ffffff"></i>
            </label>
          </nav>
        </div>

        <main>
          <div class="main-page">
            <img src="/img/earn-big.png" class="go-bigger" alt="" />

            <h2>
              <span class="buy-automatically">
                Earn consistent recurring income
              </span>
              <br />
              from your most loyal fans.
            </h2>

            <div class="cta-btn">
              <a :href="SIGN_UP_URL" class="signup"> Sign-up for Free </a>
              <!-- <a
                href="https://dashboard.meetbit.io/meeting/HelixPay"
                class="schedule"
              >
                Schedule Demo
              </a> -->
            </div>
          </div>
        </main>
      </div>

      <img src="/img/blur-circle-cc.svg" class="blur1" alt="" />
      <img src="/img/blur-circle-cc.svg" class="blur2" alt="" />
      <img src="/img/blur-circle-cc.svg" class="blur3" alt="" />
    </header>

    <div class="hero">
      <div class="membership-subs">
        <CCCreators />
        <CCCards />
        <CCStorefront />
        <HelixCreatorTech />
        <CCEngagement />
        <CCNetflix />
        <CCDiscord />
        <CCMerch />
        <CCSupport />
        <CCTikTok />
        <HoverArticle />
        <MovingDiv />
        <CCLaunchSubscriptions />
      </div>

      <MainCCFooter />
    </div>
  </div>
</template>

<script setup>
import CCCards from '@/components/CCCards.vue';
import CCCreators from '@/components/CCCreators.vue';
import CCDiscord from '@/components/CCDiscord.vue';
import CCEngagement from '@/components/CCEngagement.vue';
import CCLaunchSubscriptions from '@/components/CCLaunchSubscriptions.vue';
import CCMerch from '@/components/CCMerch.vue';
import CCNetflix from '@/components/CCNetflix.vue';
import CCStorefront from '@/components/CCStorefront.vue';
import CCSupport from '@/components/CCSupport.vue';
import CCTikTok from '@/components/CCTikTok.vue';
import HelixCreatorTech from '@/components/HelixCreatorTech.vue';
import HoverArticle from '@/components/HoverArticle.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import MovingDiv from '@/components/MovingDiv.vue';
import { CONSOLE_DOCS_URL, SIGN_UP_URL } from '@/constants';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

useHead(
  buildPageMeta({
    title: 'Creators | E-commerce Technology for Creators & Communities',
    description:
      'Content Creators in the Philippines use HelixPay to build a webstore, offer ecommerce experiences, and share exclusive content to fans.',
    url: 'https://www.helixpay.ph/creators',
    image: 'https://www.helixpay.ph/img/creators-meta-1.jpg',
  })
);
</script>

<style scoped>
.loyalFansMobile {
  display: none;
}

.forWeb {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ff5f8f;
  transition: 0.2s ease-in-out;
}

.forWeb:hover {
  background-color: #ff5f8f;
}

.forMobile {
  display: none;
}

.forMobileView {
  display: none;
}

hr {
  background: #f3f7f8;
  height: 2rem;
  border: none;
  outline: none;
}

li ul a li {
  width: 100%;
  display: flex;
  gap: 1rem;
}

li ul a img {
  width: 7%;
}

li ul p {
  font-weight: 500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.helixpay-main-logo {
  width: 10rem;
  height: 100%;
}

.wrapper {
  background-color: none;
  background: none;
  z-index: 999;
  width: 100%;
  margin: auto;
}

.wrapper nav {
  background-color: none;
  background: none;
  position: sticky;
  width: 100%;
  margin: auto;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}

nav .content {
  background-color: none;
  background: none;
  width: 71rem;
  margin: auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

nav .content #links {
  background-color: none;
  background: none;
  display: flex;
  justify-content: space-between;
}

.content .logo a {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.content #links li {
  list-style: none;
  line-height: 70px;
}

.content #links li ul {
  display: grid;
}

.content #links ul li {
  display: flex;
  align-items: center;
}

.content #links ul li img {
  width: 30px;
  height: 30px;
}

.content #links li ul li a {
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.content #links li a,
.content #links li label {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 9px 17px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.content #links li label {
  display: none;
}
.content #links li a:hover,
.content #links li label:hover {
  background: #ff7ea5;
}

.wrapper .menu-icon {
  display: none;
  color: black;
  font-size: 1px;
  font-weight: 900;
  cursor: pointer;
  line-height: 50px;
  width: 55px;
  text-align: center;
  background-image: url('/img/nav-bars.svg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 1.7rem;
}

.wrapper .menu-icon {
  display: none;
}
.wrapper #show-search:checked ~ .search-icon i::before {
  content: '\f00d';
}
.wrapper .search-box {
  position: absolute;
  height: 100%;
  max-width: calc(100% - 50px);
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.wrapper #show-search:checked ~ .search-box {
  opacity: 1;
  pointer-events: auto;
}

.wrapper input[type='checkbox'] {
  display: none;
}

/* Dropdown Menu code start */

.content #links ul {
  position: absolute;
  background: #ff5f8f;
  border-radius: 5px;
  top: 80px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.content #links li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
.content #links ul li a {
  display: block;
  width: 100%;
  line-height: 30px;
  border-radius: 0px !important;
}
.content #links ul ul {
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content #links ul li {
  position: relative;
}
.content #links ul li:hover ul {
  top: 0;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN HERO ~~~~ */

header {
  background-color: black;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

main {
  width: 100%;
  height: inherit;
  overflow: hidden;
  position: relative;
  padding: 3.5rem 0;
}

.main-page {
  display: grid;
  gap: 3rem;
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: flex-start;
  grid-template-columns: 1fr;
}

.main-page h2 {
  color: white;
  font-size: 1.5rem;
  text-align: center;
}

.buy-automatically {
  color: #ff5f8f;
  font-weight: 700;
}

.go-bigger {
  width: 100%;
  margin: auto;
  pointer-events: none;
}

.cta-btn {
  width: 50%;
  margin: auto;
  display: flex;
}

.cta-btn a {
  text-align: center;
  margin-top: 10px;
  padding: 1rem;
}

.signup {
  width: 50%;
  background-color: #ff5f8f;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.schedule {
  width: 50%;
  background-color: #25a4e1;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.signup:hover {
  background-color: #eb4c7b;
}

.schedule:hover {
  background-color: #2094ca;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN CIRCLE */

.blur1 {
  opacity: 0.7;
  width: 30%;
  right: -15%;
  bottom: -30%;
  z-index: -1;
  position: absolute;
  pointer-events: none;
  animation: move 120s ease-out infinite;
}

@keyframes move {
  0% {
    right: 1%;
  }
  100% {
    right: 100%;
    bottom: 70%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 2 */

.blur2 {
  opacity: 0.5;
  width: 15%;
  left: 1%;
  bottom: -30%;
  z-index: -1;
  position: absolute;
  pointer-events: none;
  animation: right 60s ease-out infinite;
}

@keyframes right {
  0% {
    width: 15%;
    left: -5%;
    bottom: 10%;
  }
  100% {
    width: 25%;
    left: 100%;
    bottom: 50%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 3 */

.blur3 {
  opacity: 0.4;
  width: 5%;
  left: 1%;
  top: -10%;
  z-index: -1;
  position: absolute;
  pointer-events: none;
  animation: lower 70s ease-out infinite;
}

@keyframes lower {
  0% {
    width: 5%;
    left: -5%;
    top: -10%;
  }
  100% {
    width: 10%;
    left: 100%;
    top: 50%;
  }
}

/* FIFTH SECTION || AUTOMATED PAYMENTS */

.hero {
  background-color: #f3f7f8;
}

@media only screen and (max-width: 1400px) {
  .main-page {
    max-width: 60rem;
  }

  .support-ph {
    width: 60rem;
  }
}

@media screen and (max-width: 1200px) {
  nav .content {
    width: 90%;
  }

  .wrapper,
  nav {
    width: 100%;
  }

  .forWeb {
    display: none;
  }

  .forMobile {
    background-color: #ff5f8f;
    color: white !important;
  }

  .forMobile:hover {
    background-color: #e44071 !important;
  }

  .helixpay-main-logo {
    margin-right: 0;
    padding-right: 0;
  }

  li ul p {
    font-size: 1rem;
  }

  .wrapper .menu-icon {
    display: block;
  }

  .content #links ul li img {
    display: none;
  }

  .wrapper #show-menu:checked ~ .menu-icon i::before {
    content: '\f00d';
  }

  nav .content #links {
    display: block;
    position: fixed;
    background: rgb(0, 0, 0);
    height: 100%;
    width: 100%;
    top: 70px;
    right: -100%;
    margin-right: 0;
    max-width: 350px;
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.3s ease;
  }

  nav #show-menu:checked ~ .content #links {
    right: 0%;
  }

  .content #links li {
    margin: 15px 20px;
  }

  .content #links li a,
  .content #links li label {
    line-height: 40px;
    font-size: 20px;
    display: block;
    padding: 8px 18px;
    cursor: pointer;
  }

  .content #links li a.desktop-link {
    display: none;
  }

  /* dropdown responsive code start */
  .content #links ul,
  .content #links ul ul {
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }

  .content #links #show-features:checked ~ ul,
  .content #links #show-services:checked ~ ul,
  .content #links #show-documentation:checked ~ ul,
  .content #links #show-items:checked ~ ul {
    max-height: 100vh;
  }

  .content #links ul li {
    margin: 7px 20px;
  }

  .content #links ul li a {
    font-size: 18px;
    line-height: 30px;
    border-radius: 5px !important;
  }

  .wrapper nav {
    max-width: 100%;
    padding: 0 20px;
  }

  nav .content #links {
    margin-left: 30px;
  }

  .content #links li a {
    padding: 8px 13px;
  }

  .blur1 {
    animation: move 30s ease-out infinite;
  }

  .blur2 {
    animation: right 20s ease-out infinite;
  }

  .blur3 {
    animation: lower 20s ease-out infinite;
  }
}

@media only screen and (max-width: 1050px) {
  .main-page {
    width: 40rem;
  }

  .main-page h2 {
    font-size: 1rem;
  }

  .cta-btn {
    margin: auto;
    width: 90%;
  }
}

/* 560PX ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MOBILE VIEW ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media only screen and (max-width: 650px) {
  .header-inputs {
    width: 90vw;
  }

  .main-page {
    width: 100%;
    height: inherit;
    margin: auto;
  }

  .go-bigger {
    width: 90%;
  }

  .cta-btn {
    display: grid;
    grid-template-columns: 1fr;
  }

  .signup,
  .schedule {
    width: 70%;
    margin: auto;
  }
}
</style>
