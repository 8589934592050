<template>
  <div>
    <header>
      <div class="header-info">
        <div class="wrapper">
          <nav>
            <input id="show-search" type="checkbox" />
            <input id="show-menu" type="checkbox" />
            <div class="content">
              <router-link to="/">
                <img
                  src="
                  /img/HPMainLogo-creators-min.png
                "
                  class="helixpay-main-logo"
                  alt="HelixPay"
                />
              </router-link>
              <ul id="links">
                <li>
                  <a href="#" class="desktop-link">Features </a>
                  <input id="show-features" type="checkbox" />
                  <label for="show-features">Features</label>
                  <ul>
                    <router-link to="/storefronts">
                      <li>
                        <img src="/img/storefront-for-navbar-min.png" alt="" />
                        <a>No-code Storefronts </a>
                      </li>
                    </router-link>
                    <router-link to="/automatednotif">
                      <li>
                        <img
                          src="/img/automated-notif-for-navbar-min.png"
                          alt=""
                        />
                        <a>Automated Notifications </a>
                      </li>
                    </router-link>
                    <router-link to="/analytics">
                      <li>
                        <img src="/img/analytics-for-navbar-min.png" alt="" />
                        <a>Analytics </a>
                      </li>
                    </router-link>
                    <router-link to="/merch">
                      <li>
                        <img src="/img/merch-for-navbar.svg" alt="" />
                        <a>Merch </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
                <li>
                  <a href="#" class="desktop-link">Documentation </a>
                  <input id="show-documentation" type="checkbox" />
                  <label for="show-documentation">Documentation</label>
                  <ul>
                    <!-- <a href="https://helixpay.readme.io/reference/introduction">
                      <li style="align-items: center">
                        <img src="/img/apidocs-for-navbar-min.png" alt="" />
                        <p>API Docs</p>
                      </li>
                    </a> -->
                    <a :href="CONSOLE_DOCS_URL">
                      <li style="align-items: center">
                        <img src="/img/consoledocs-for-navbar-min.png" alt="" />
                        <p>Console Docs</p>
                      </li>
                    </a>
                  </ul>
                </li>
                <router-link to="/pricing">
                  <li><a>Pricing </a></li>
                </router-link>
                <router-link to="/incomecalculator">
                  <li><a>Income Calculator </a></li>
                </router-link>
                <a href="https://helixevents.beehiiv.com/">
                  <li><a>Insights </a></li>
                </a>
                <router-link to="/articles">
                  <li><a>Articles</a></li>
                </router-link>
                <li>
                  <a
                    class="forMobile"
                    onclick="window.open('https://console.helixpay.ph/#/login')"
                  >
                    Log In
                  </a>
                </li>
              </ul>
              <a
                class="forWeb"
                onclick="window.open('https://console.helixpay.ph/#/login')"
              >
                Log In
              </a>
            </div>
            <label for="show-menu" class="menu-icon">
              <i class="fas fa-bars fa-1x" style="color: #ffffff"></i>
            </label>
          </nav>
        </div>

        <main>
          <div class="main-page">
            <div class="left-part">
              <h1>
                <span class="title-design">Automated Subscriptions</span> to
                grow your business
              </h1>
              <div class="cta-btn">
                <a :href="SIGN_UP_URL" class="signup"> Sign-up for Free </a>
                <!-- <a
                  href="https://dashboard.meetbit.io/meeting/HelixPay"
                  class="schedule"
                >
                  Schedule Demo
                </a> -->
              </div>
            </div>
            <div class="right-part">
              <img src="img/skinlab-header-min.png" class="skinlab" alt="" />
              <img src="img/skinlab-header1-min.png" class="skinlab1" alt="" />
              <img
                src="img/skinlab-mobile-view-min.png"
                class="forMobileView"
                alt=""
              />
            </div>
          </div>
        </main>
      </div>
    </header>

    <div class="second-section">
      <div class="second-section-info">
        <h2>
          Customers love your products and services, subscriptions let them
          <span class="buy-automatically">buy automatically.</span>
        </h2>
        <h3>
          Subscriptions increase revenue retention and customer lifetime value
          by 10x compared to traditional sales.
        </h3>

        <h3>
          HelixPay is built for the Philippines.
          <span class="ph-flag-icon-first">
            <img src="@/assets/images/ph-flag-icon-min.png" alt="" />
          </span>
        </h3>
      </div>
    </div>

    <div class="hero">
      <hr />
      <TopCompanies />
      <WebVid />
      <AutomatedPayments />
      <CustomizedSubscriptions />
      <RevenueGrowth />
      <Integrations />
      <SupportPH />
      <WorldClassTech />
      <Launch1Day />
      <FiveSteps />
      <SecondaryFAQs />
      <LaunchSubscriptions />
      <MainFooter />
    </div>
  </div>
</template>

<script setup>
import AutomatedPayments from '@/components/AutomatedPayments.vue';
import CustomizedSubscriptions from '@/components/CustomizedSubscriptions.vue';
import FiveSteps from '@/components/FiveSteps.vue';
import Integrations from '@/components/Integrations.vue';
import Launch1Day from '@/components/Launch1Day.vue';
import LaunchSubscriptions from '@/components/LaunchSubscriptions.vue';
import MainFooter from '@/components/MainFooter.vue';
import RevenueGrowth from '@/components/RevenueGrowth.vue';
import SecondaryFAQs from '@/components/SecondaryFAQs.vue';
import SupportPH from '@/components/SupportPH.vue';
import TopCompanies from '@/components/TopCompanies.vue';
import WebVid from '@/components/WebVid.vue';
import WorldClassTech from '@/components/WorldClassTech.vue';
import { CONSOLE_DOCS_URL, SIGN_UP_URL } from '@/constants';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

useHead(
  buildPageMeta({
    title: 'Subscriptions | Ecommerce Technology for Creators & Communities',
    description:
      'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
    url: 'https://www.helixpay.ph/subscriptions',
    image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
  })
);
</script>

<style scoped>
.forWeb {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ff5f8f;
  transition: 0.2s ease-in-out;
}

.forWeb:hover {
  background-color: #e44071;
}

.forMobile {
  display: none;
}

.forMobileView {
  display: none;
}

hr {
  background: #f3f7f8;
  height: 2rem;
  border: none;
  outline: none;
}

li ul a li {
  width: 100%;
  display: flex;
  gap: 1rem;
}

li ul a img {
  width: 7%;
}

li ul p {
  font-weight: 500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.helixpay-main-logo {
  width: 10rem;
  height: 100%;
}

.wrapper {
  background-color: none;
  background: none;
  z-index: 999;
  width: 100%;
  margin: auto;
}

.wrapper nav {
  background-color: none;
  background: none;
  position: sticky;
  width: 100%;
  margin: auto;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}

nav .content {
  background-color: none;
  background: none;
  width: 71rem;
  margin: auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

nav .content #links {
  background-color: none;
  background: none;
  display: flex;
  justify-content: space-between;
}

.content .logo a {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.content #links li {
  list-style: none;
  line-height: 70px;
}

.content #links li ul {
  display: grid;
}

.content #links ul li {
  display: flex;
  align-items: center;
}

.content #links ul li img {
  width: 30px;
  height: 30px;
}

.content #links li ul li a {
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.content #links li a,
.content #links li label {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 9px 17px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.content #links li label {
  display: none;
}
.content #links li a:hover,
.content #links li label:hover {
  background: #ae3b5e;
}

.wrapper .menu-icon {
  display: none;
  color: black;
  font-size: 1px;
  font-weight: 900;
  cursor: pointer;
  line-height: 50px;
  width: 55px;
  text-align: center;
  background-image: url('/img/nav-bars.svg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 1.7rem;
}

.wrapper .menu-icon {
  display: none;
}
.wrapper #show-search:checked ~ .search-icon i::before {
  content: '\f00d';
}
.wrapper .search-box {
  position: absolute;
  height: 100%;
  max-width: calc(100% - 50px);
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.wrapper #show-search:checked ~ .search-box {
  opacity: 1;
  pointer-events: auto;
}

.wrapper input[type='checkbox'] {
  display: none;
}

/* Dropdown Menu code start */

.content #links ul {
  position: absolute;
  background: #ff5f8f;
  border-radius: 5px;
  top: 80px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.content #links li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
.content #links ul li a {
  display: block;
  width: 100%;
  line-height: 30px;
  border-radius: 0px !important;
}
.content #links ul ul {
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content #links ul li {
  position: relative;
}
.content #links ul li:hover ul {
  top: 0;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN HERO ~~~~ */

header {
  background-image: url('/img/hero-wallpaper-min.gif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-info {
  width: 100%;
  background-image: url('/img/white-bg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

main {
  width: 100%;
  height: inherit;
  overflow-x: hidden;
  position: relative;
}

.main-page {
  height: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-content: flex-start;
  justify-content: center;
  z-index: -1;
}

.left-part {
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  text-align: center;
  align-items: left;
  align-content: flex-start;
  margin-top: 10rem;
}

.left-part h1 {
  width: 100%;
  margin: auto;
  font-size: 2.8rem;
  font-weight: 400;
  color: white;
  line-height: 1;
  margin-bottom: 2rem;
  text-align: left;
}

.cta-btn {
  display: flex;
}

.cta-btn a {
  text-align: center;
  margin-top: 10px;
  padding: 1rem;
}

.right-part {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.skinlab {
  position: relative;
  z-index: 1;
  width: 15rem;
  margin-top: 3rem;
  margin-left: -3rem;
}

.skinlab1 {
  position: absolute;
  margin-left: -14rem;
  margin-top: -3.5rem;
  width: 55rem;
}

.title-design {
  font-weight: 700;
  color: white;
}

.signup {
  width: 100%;
  background-color: white;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.schedule {
  width: 100%;
  background-color: #ff5f8f;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.signup:hover {
  background-color: rgb(228, 228, 228);
}

.schedule:hover {
  background-color: #e44071;
}

/* SECOND SECTION */

.second-section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  height: inherit;
  align-items: center;
  text-align: center;
}

.second-section-info {
  width: 53rem;
  margin: auto;
  line-height: 1.5;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.second-section h2 {
  text-align: left;
  margin: auto;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.buy-automatically {
  color: #25a4e1;
}

.second-section h3 {
  text-align: left;
  margin: auto;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  font-weight: 500;
  opacity: 0.6;
}

.second-section h3:nth-child(2) {
  margin-top: 1rem;
}

.ph-flag-icon-first img {
  width: 3%;
  height: 100%;
}

.hero {
  background-color: #f3f7f8;
}

@media only screen and (max-width: 1400px) {
  .second-section-info {
    width: 40rem;
  }
}

@media screen and (max-width: 1200px) {
  .main-page {
    width: 40rem;
    margin: auto;
  }

  nav .content {
    width: 90%;
  }

  .wrapper,
  nav {
    width: 100%;
  }

  .forWeb {
    display: none;
  }

  .forMobile {
    display: block;
    background-color: #ff5f8f;
  }

  .helixpay-main-logo {
    margin-right: 0;
    padding-right: 0;
  }

  li ul p {
    font-size: 1rem;
  }

  .wrapper .menu-icon {
    display: block;
  }

  .content #links ul li img {
    display: none;
  }

  .wrapper #show-menu:checked ~ .menu-icon i::before {
    content: '\f00d';
  }

  nav .content #links {
    display: block;
    position: fixed;
    background: rgb(32, 32, 32);
    height: 100%;
    width: 100%;
    top: 70px;
    right: -100%;
    margin-right: 0;
    max-width: 350px;
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.3s ease;
  }

  nav #show-menu:checked ~ .content #links {
    right: 0%;
  }

  .content #links li {
    margin: 15px 20px;
  }

  .content #links li a,
  .content #links li label {
    line-height: 40px;
    font-size: 20px;
    display: block;
    padding: 8px 18px;
    cursor: pointer;
  }

  .content #links li a.desktop-link {
    display: none;
  }

  /* dropdown responsive code start */
  .content #links ul,
  .content #links ul ul {
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }

  .content #links #show-features:checked ~ ul,
  .content #links #show-services:checked ~ ul,
  .content #links #show-documentation:checked ~ ul,
  .content #links #show-items:checked ~ ul {
    max-height: 100vh;
  }

  .content #links ul li {
    margin: 7px 20px;
  }

  .content #links ul li a {
    font-size: 18px;
    line-height: 30px;
    border-radius: 5px !important;
  }

  .wrapper nav {
    max-width: 100%;
    padding: 0 20px;
  }

  nav .content #links {
    margin-left: 30px;
  }

  .content #links li a {
    padding: 8px 13px;
  }

  .header-info {
    width: 100%;
    background-image: url('/img/white-bg-mobile.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  main {
    height: inherit;
  }

  .main-page {
    height: inherit;
    gap: 2rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 0;
    padding-bottom: 0;
  }

  .left-part {
    margin: 5rem auto 0 auto;
    width: 100%;
  }

  .left-part h1 {
    text-align: center;
  }

  .cta-btn {
    width: 60%;
    margin: auto;
  }

  .forMobileView {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 0;
  }

  .skinlab,
  .skinlab1 {
    display: none;
  }

  .second-section {
    padding: 7rem 2rem 2rem 2rem;
  }
}

@media only screen and (max-width: 1050px) {
  .main-page {
    width: 40rem;
  }

  .left-part h1 {
    width: 80%;
    margin-bottom: 2rem;
  }

  .right-part {
    display: grid;
    grid-template-columns: 1fr;
  }

  .second-section-info {
    width: 30rem;
  }

  .second-section-info h2 {
    font-size: 1.5rem;
  }

  .second-section-info h3 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 650px) {
  .cta-btn {
    display: grid;
    width: 60%;
    margin: auto;
  }

  .cta-btn a {
    font-size: 0.9rem;
  }

  main {
    height: inherit;
  }

  .main-page {
    gap: 2rem;
    width: 100%;
    height: inherit;
    margin: auto;
    padding-top: 3.5rem;
  }

  .left-part {
    width: 100%;
    margin: auto;
    margin-top: 0;
  }

  .left-part h1 {
    text-align: center;
    font-size: 1.7rem;
  }

  .forMobileView {
    width: 90%;
  }

  .skinlab,
  .skinlab1 {
    display: none;
  }

  .second-section-info {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .second-section-info h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  .wrapper nav {
    padding: 0 10px;
  }

  .content .logo a {
    font-size: 27px;
  }

  .wrapper .search-box {
    max-width: calc(100% - 70px);
  }

  .wrapper .search-box .go-icon {
    width: 30px;
    right: 0;
  }

  .wrapper .search-box input {
    padding-right: 30px;
  }
}
</style>
