<template>
  <div class="top-companies">
    <div class="top-companies-info">
      <h1>
        Powering subscriptions for top companies, associations & creators:
      </h1>
      <div
        v-motion
        class="logo-section forMobileView"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="/img/gs-yardstick-min.png" alt="" />
        <img src="/img/gs-commoner-min.png" alt="" />
        <img src="/img/gs-wish-min.png" alt="" />
        <img src="/img/gs-remedy-min.png" alt="" />
        <img src="/img/gs-boozy-min.png" alt="" />
        <img src="/img/gs-acro-min.png" alt="" />
        <img src="/img/gs-onala-min.png" alt="" />
        <img src="/img/gs-bima-min.png" alt="" />
        <img src="/img/gs-mediclick-min.png" alt="" />
        <img src="/img/gs-candid-min.png" alt="" />
        <img src="/img/gs-tempo-min.png" alt="" />
        <img src="/img/gs-prettyhuge-min.png" alt="" />
        <img src="/img/gs-future-min.png" alt="" />
        <img src="/img/gs-mayani-min.png" alt="" />
        <img src="/img/gs-loopy-min.png" alt="" />
        <img src="/img/gs-kwenta-min.png" alt="" />
        <img src="/img/gs-storage-min.png" alt="" />
        <img src="/img/gs-allcare-min.png" alt="" />
        <img src="/img/gs-lavada-min.png" alt="" />
        <img src="/img/gs-farmtop-min.png" alt="" />
        <img src="/img/gs-kmc-min.png" alt="" />
        <img src="/img/gs-nook-min.png" alt="" />
        <img src="/img/gs-eagle-min.png" alt="" />
        <img src="/img/gs-icf-min.png" alt="" />
        <img src="/img/gs-paw-min.png" class="pawpassion" alt="" />
      </div>
      <div
        v-motion
        class="logo-section first-section"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="/img/gs-yardstick-min.png" alt="" />
        <img src="/img/gs-commoner-min.png" alt="" />
        <img src="/img/gs-wish-min.png" alt="" />
        <img src="/img/gs-remedy-min.png" alt="" />
        <img src="/img/gs-boozy-min.png" alt="" />
        <img src="/img/gs-acro-min.png" alt="" />
        <img src="/img/gs-onala-min.png" alt="" />
        <img src="/img/gs-bima-min.png" alt="" />
      </div>
      <div
        v-motion
        class="logo-section second-section"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="/img/gs-mediclick-min.png" alt="" />
        <img src="/img/gs-candid-min.png" alt="" />
        <img src="/img/gs-tempo-min.png" alt="" />
        <img src="/img/gs-prettyhuge-min.png" alt="" />
        <img src="/img/gs-future-min.png" alt="" />
        <img src="/img/gs-mayani-min.png" alt="" />
        <img src="/img/gs-loopy-min.png" alt="" />
      </div>
      <div
        v-motion
        class="logo-section third-section"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="/img/gs-kwenta-min.png" alt="" />
        <img src="/img/gs-storage-min.png" alt="" />
        <img src="/img/gs-allcare-min.png" alt="" />
        <img src="/img/gs-lavada-min.png" alt="" />
        <img src="/img/gs-farmtop-min.png" alt="" />
        <img src="/img/gs-kmc-min.png" alt="" />
      </div>
      <div
        v-motion
        class="logo-section forth-section"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="/img/gs-nook-min.png" alt="" />
        <img src="/img/gs-eagle-min.png" alt="" />
        <img src="/img/gs-icf-min.png" alt="" />
        <img src="/img/gs-paw-min.png" class="pawpassion" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobileView {
  display: none;
}

.top-companies {
  width: 100%;
  background-color: white;
  border-radius: 20px;
}

.top-companies-info {
  width: 90rem;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.top-companies-info h1 {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}

.logo-section {
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: auto;
}

.logo-section img {
  width: 50%;
}

.first-section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  align-items: center;
  margin: auto;
  padding-top: 3rem;
}

.second-section {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin: auto;
  margin-top: 2rem;
}

.third-section {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin: auto;
  margin-top: 2rem;
}

.forth-section {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin: auto;
  margin-top: 2rem;
}

.logo-section img {
  opacity: 0.8;
}

.first-section img {
  align-items: center;
  justify-content: center;
  margin: auto;
}

.second-section img {
  align-items: center;
  margin: auto;
}

.third-section img {
  align-items: center;
  margin: auto;
}

.forth-section img {
  align-items: center;
  margin: auto;
}

@media only screen and (max-width: 1500px) {
  .top-companies-info {
    width: 80rem;
  }
}

@media only screen and (max-width: 1350px) {
  .top-companies-info {
    width: 70rem;
  }
}

@media only screen and (max-width: 1150px) {
  .top-companies-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1000px) {
  .top-companies-info {
    width: 45rem;
  }

  .logo-section {
    gap: 1.5rem;
  }

  .first-section img,
  .second-section img,
  .third-section img,
  .forth-section img {
    width: 4rem;
  }
}

@media only screen and (max-width: 800px) {
  .top-companies-info {
    width: 90%;
  }

  .first-section,
  .second-section,
  .third-section,
  .forth-section {
    display: none;
  }

  .logo-section {
    width: 100%;
  }

  .forMobileView {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding-top: 3rem;
  }

  .forMobileView img {
    width: 80%;
    display: block;
    margin: auto;
  }
}

@media only screen and (max-width: 650px) {
  .top-companies-info h1 {
    font-size: 1rem;
  }

  .logo-section img {
    width: 70%;
  }
}
</style>
