<template>
  <div class="our-clients">
    <h1>Meet our Creator Partners</h1>

    <div class="clients forWeb">
      <!-- PURVEYR -->
      <div class="main-card purveyr">
        <div class="purveyr-info">
          <h3>Purveyr</h3>
          <p>46K Facebook followers</p>
        </div>
        <img
          src="/img/cc-purveyr.png"
          class="img purveyr"
          alt=""
          onclick="window.open('https://purveyr.helixpay.ph/')"
        />
      </div>

      <!-- KILIMANGURU -->
      <div class="main-card kili">
        <div class="kili-info">
          <h3>Dr. Kilimanguru</h3>
          <p>Content Creator</p>
          <p>4.5M TikTok followers</p>
        </div>
        <img
          src="/img/kilimanguru.png"
          class="img kilimanguru"
          alt=""
          onclick="window.open('https://char.drkilimanguru.com/')"
        />
      </div>

      <!-- POOR TRAVELER -->
      <div class="main-card poor">
        <div class="poor-info">
          <h3>The Poor Traveler</h3>
          <p>Content Creator</p>
          <p>40K TikTok followers</p>
        </div>
        <img
          src="/img/cc-poortraveler.png"
          class="img poortraveler"
          alt=""
          onclick="window.open('https://thepoortraveler.helixpay.ph/')"
        />
      </div>

      <!-- SPART FARNACIO -->
      <div class="main-card spart">
        <div class="spart-info">
          <h3>Spart Farnacio</h3>
          <p>Content Creator</p>
          <p>660K YouTube followers</p>
        </div>
        <img
          src="/img/spart-farnacio.png"
          class="img spartfarnacio"
          alt=""
          onclick="window.open('https://store.spartuniverse.com/')"
        />
      </div>

      <!-- AWKP -->
      <div class="main-card awkp">
        <div class="awkp-info">
          <h3>AWKP</h3>
          <p>Spotify Podcast</p>
        </div>
        <img
          src="/img/cc-awkp.png"
          class="img awkp"
          alt=""
          onclick="window.open('https://angwalangkwentangpodcast.com/')"
        />
      </div>

      <!-- SAY TIOCO -->
      <div class="main-card tioco">
        <div class="tioco-info">
          <h3>Say Tioco</h3>
          <p>243K Facebook followers</p>
        </div>
        <img
          src="/img/saytioco.png"
          class="img tioco"
          alt=""
          onclick="window.open('https://saytioco.memberships.ph/')"
        />
      </div>

      <!-- MNL48 -->
      <div class="main-card mnl48">
        <div class="mnl48-info">
          <h3>MNL48</h3>
          <p>1.3M Facebook followers</p>
        </div>
        <img
          src="/img/cc-mnl48.png"
          class="img mnl48"
          alt=""
          onclick="window.open('https://mnl48.helixpay.ph/')"
        />
      </div>

      <!-- XZAR LIM -->
      <div class="main-card lim">
        <div class="lim-info">
          <h3>Xzar Lim</h3>
          <p>320K Facebook followers</p>
        </div>
        <img
          src="/img/lim.png"
          class="img lim"
          alt=""
          onclick="window.open('https://xzarlim.com/')"
        />
      </div>

      <!-- YASMIN ASISTIDO -->
      <div class="main-card yasmin">
        <div class="yasmin-info">
          <h3>Yasmin Asistido</h3>
          <p>330K Facebook followers</p>
        </div>
        <img
          src="/img/cc-yasmin.png"
          class="img yasmin"
          alt=""
          onclick="window.open('https://kweenyasmin.com/')"
        />
      </div>

      <!-- KOOLPPALS -->
      <div class="main-card koolpals">
        <div class="koolpals-info">
          <h3>The Koolpals</h3>
          <p>38K Facebook followers</p>
        </div>
        <img
          src="/img/cc-koolpals.png"
          class="img koolpals"
          alt=""
          onclick="window.open('https://patreonsaints.thekoolpals.com/')"
        />
      </div>
    </div>

    <div id="mobileOnly" class="scrolling snaps-inline">
      <!-- PURVEYR -->
      <div class="scroll-element main-card purveyr">
        <div class="purveyr-info">
          <h3>Purveyr</h3>
          <p>46K Facebook followers</p>
        </div>
        <img
          src="/img/cc-purveyr.png"
          class="img purveyr"
          alt=""
          onclick="window.open('https://purveyr.helixpay.ph/')"
        />
      </div>

      <!-- KILIMANGURU -->
      <div class="scroll-element main-card kili">
        <div class="kili-info">
          <h3>Dr. Kilimanguru</h3>
          <p>Content Creator</p>
          <p>4.5M TikTok followers</p>
        </div>
        <img
          src="/img/kilimanguru.png"
          class="img kilimanguru"
          alt=""
          onclick="window.open('https://char.drkilimanguru.com/')"
        />
      </div>

      <!-- POOR TRAVELER -->
      <div class="scroll-element main-card poor">
        <div class="poor-info">
          <h3>The Poor Traveler</h3>
          <p>Content Creator</p>
          <p>40K TikTok followers</p>
        </div>
        <img
          src="/img/cc-poortraveler.png"
          class="img poortraveler"
          alt=""
          onclick="window.open('https://thepoortraveler.helixpay.ph/')"
        />
      </div>

      <!-- SPART FARNACIO -->
      <div class="scroll-element main-card spart">
        <div class="spart-info">
          <h3>Spart Farnacio</h3>
          <p>Content Creator</p>
          <p>660K YouTube followers</p>
        </div>
        <img
          src="/img/spart-farnacio.png"
          class="img spartfarnacio"
          alt=""
          onclick="window.open('https://store.spartuniverse.com/')"
        />
      </div>

      <!-- AWKP -->
      <div class="scroll-element main-card awkp">
        <div class="awkp-info">
          <h3>AWKP</h3>
          <p>Spotify Podcast</p>
        </div>
        <img
          src="/img/cc-awkp.png"
          class="img awkp"
          alt=""
          onclick="window.open('https://angwalangkwentangpodcast.com/')"
        />
      </div>

      <!-- SAY TIOCO -->
      <div class="scroll-element main-card tioco">
        <div class="tioco-info">
          <h3>Say Tioco</h3>
          <p>243K Facebook followers</p>
        </div>
        <img
          src="/img/saytioco.png"
          class="img tioco"
          alt=""
          onclick="window.open('https://saytioco.memberships.ph/')"
        />
      </div>

      <!-- MNL48 -->
      <div class="scroll-element main-card mnl48">
        <div class="mnl48-info">
          <h3>MNL48</h3>
          <p>1.3M Facebook followers</p>
        </div>
        <img
          src="/img/cc-mnl48.png"
          class="img mnl48"
          alt=""
          onclick="window.open('https://mnl48.helixpay.ph/')"
        />
      </div>

      <!-- XZAR LIM -->
      <div class="scroll-element main-card lim">
        <div class="lim-info">
          <h3>Xzar Lim</h3>
          <p>320K Facebook followers</p>
        </div>
        <img
          src="/img/lim.png"
          class="img lim"
          alt=""
          onclick="window.open('https://xzarlim.com/')"
        />
      </div>

      <!-- YASMIN ASISTIDO -->
      <div class="scroll-element main-card yasmin">
        <div class="yasmin-info">
          <h3>Yasmin Asistido</h3>
          <p>330K Facebook followers</p>
        </div>
        <img
          src="/img/cc-yasmin.png"
          class="img yasmin"
          alt=""
          onclick="window.open('https://kweenyasmin.com/')"
        />
      </div>

      <!-- KOOLPPALS -->
      <div class="scroll-element main-card koolpals">
        <div class="koolpals-info">
          <h3>The Koolpals</h3>
          <p>38K Facebook followers</p>
        </div>
        <img
          src="/img/cc-koolpals.png"
          class="img koolpals"
          alt=""
          onclick="window.open('https://patreonsaints.thekoolpals.com/')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
#mobileOnly {
  display: none;
}

.our-clients {
  width: 100%;
  background-color: black;
}

.our-clients h1 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 2rem 0;
  color: white;
}

.clients {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.clients img {
  position: relative;
  width: 100%;
  filter: gray;
  -webkit-filter: grayscale(1);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.clients img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  cursor: pointer;
}

.kili,
.poor,
.spart,
.awkp,
.purveyr,
.tioco,
.mnl48,
.lim,
.yasmin,
.koolpals {
  position: relative;
}

.kili:hover .kili-info,
.poor:hover .poor-info,
.spart:hover .spart-info,
.awkp:hover .awkp-info,
.purveyr:hover .purveyr-info,
.tioco:hover .tioco-info,
.mnl48:hover .mnl48-info,
.lim:hover .lim-info,
.yasmin:hover .yasmin-info,
.koolpals:hover .koolpals-info {
  opacity: 1;
}

.kili-info,
.poor-info,
.spart-info,
.awkp-info,
.purveyr-info,
.tioco-info,
.mnl48-info,
.lim-info,
.yasmin-info,
.koolpals-info {
  opacity: 0;
  margin-left: 1rem;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 1px 5px;
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  z-index: 999;
  position: absolute;
  bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.kili-info h3,
.poor-info h3,
.spart-info h3,
.awkp-info h3,
.purveyr-info h3,
.tioco-info h3,
.mnl48-info h3,
.lim-info h3,
.yasmin-info h3,
.koolpals-info h3 {
  font-weight: 700;
}

.kili-info p,
.poor-info p,
.spart-info p,
.awkp-info p,
.purveyr-info p,
.tioco-info p,
.mnl48-info p,
.lim-info p,
.yasmin-info p,
.koolpals-info p {
  font-weight: 700;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1200px) {
  .kili-info h3,
  .poor-info h3,
  .spart-info h3,
  .awkp-info h3,
  .purveyr-info h3,
  .tiocoinfo h3,
  .mnl48-info h3,
  .lim-info h3,
  .yasmin-info h3,
  .koolpals-info h3 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 1050px) {
  .our-clients h1 {
    font-size: 2rem;
  }

  .forWeb {
    display: none;
  }

  #mobileOnly {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 63%;

    overflow-x: auto;
    overscroll-behavior-inline: contain;
  }

  #mobileOnly::-webkit-scrollbar {
    display: none;
  }

  .snaps-inline {
    scroll-snap-type: inline mandatory;
  }

  .snaps-inline > * {
    scroll-snap-align: start;
  }

  .scroll-element img {
    width: 100%;
    filter: gray;
    -webkit-filter: grayscale(1);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }

  .scroll-element img:hover {
    filter: none;
    -webkit-filter: grayscale(0);
    cursor: pointer;
  }
}

@media only screen and (max-width: 650px) {
  .our-clients h1 {
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
  }
}
</style>
