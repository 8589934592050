<template>
  <div class="storefronts">
    <div class="storefronts-info">
      <div
        v-motion
        class="social-media-right-part forMobile"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="img/storefronts-min.png" alt="" />
      </div>

      <div class="social-media-left-part">
        <h4>Storefronts</h4>
        <h1>Custom storefronts for creators</h1>

        <li class="first-list-item">
          <p>Launch a customized e-commerce website in minutes.</p>
        </li>
        <li class="first-list-item">
          <p>No need to hire a developer.</p>
        </li>
        <li class="first-list-item">
          <p>Fully customizable branding, colors and style.</p>
        </li>
        <li class="first-list-item">
          <p>Easily add and edit products and prices anytime.</p>
        </li>
      </div>

      <div
        v-motion
        class="social-media-right-part forWeb"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="img/storefronts-min.png" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none;
}

.storefronts {
  width: 100%;
  background-color: #f3f7f8;
}

.storefronts-info {
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-left: 2px solid white;
}

.social-media-left-part h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 25px;
  padding-top: 1rem;
  color: #ff5f8f;
}

.social-media-left-part h1 {
  line-height: 1.2;
  font-size: 4rem;
  font-weight: 800;
  margin-left: -2px;
  padding-left: 1.45rem;
  border-left: 2px solid #ff5f8f;
}

.social-media-left-part p {
  line-height: 1.5;
  font-weight: 500;
  opacity: 0.6;
}

.social-media-left-part li {
  width: 100%;
  align-items: center;
  font-size: 1rem;
  top: -10rem;
  margin-bottom: 20px;
  margin-left: 1.45rem;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list-cc.svg');
  background-repeat: no-repeat;
  background-position: 0% 40%;
  background-size: 1rem;
}

.social-media-right-part {
  width: 70%;
  margin: auto;
}

.social-media-right-part img {
  width: 100%;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .storefronts-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .storefronts-info {
    width: 40rem;
    grid-template-columns: 1fr;
  }

  .social-media-right-part img {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 650px) {
  .storefronts-info {
    width: 90%;
    border-left: none;
  }

  .social-media-left-part h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .social-media-left-part h1 {
    font-size: 2rem;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-left-part p {
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-logos {
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-right-part {
    width: 100%;
  }

  .social-media-right-part img {
    width: 90%;
    margin-bottom: 2rem;
  }

  .social-media-left-part li {
    margin-left: 0;
  }
}
</style>
