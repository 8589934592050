<template>
  <div>
    <div class="main-content">
      <div class="title">
        <h1>Empowering Filipino content creators to earn.</h1>
        <p>Creator friendly tools. Full creative freedom. Just be you.</p>
      </div>

      <div class="navigation">
        <div id="show-mobile" class="image-area">
          <img id="slider" :src="img" alt="" />
        </div>

        <div class="nav-area">
          <ul>
            <a @mouseenter="changeImg('/img/Arts.svg')">
              <li>
                <h3>Music and Arts Communities</h3>
                <p>Earn recurring fundings for your community.</p>
              </li>
            </a>
            <hr />
            <a @mouseenter="changeImg('/img/Hearttypes.svg')">
              <li>
                <h3>Relationship Advice</h3>
                <p>Earn from your tips and relationship experiences.</p>
              </li>
            </a>
            <hr />
            <a @mouseenter="changeImg('/img/Donations.svg')">
              <li>
                <h3>Donations and Non-profits</h3>
                <p>Earn recurring fundings for your cause.</p>
              </li>
            </a>
            <hr />
            <a @mouseenter="changeImg('/img/Foodtypes.svg')">
              <li>
                <h3>Food / Cooking Content</h3>
                <p>Turn your love of food into recurring earnings.</p>
              </li>
            </a>
            <hr />
            <a @mouseenter="changeImg('/img/Journaling.svg')">
              <li>
                <h3>Lifestyle Journals</h3>
                <p>Turn your life experiences into a business.</p>
              </li>
            </a>
          </ul>
        </div>

        <div
          id="hide-mobile"
          v-motion
          class="image-area"
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        >
          <img id="slider" :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: '/img/Arts.svg',
    };
  },

  methods: {
    changeImg: function (img) {
      this.img = img;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-content {
  width: 100%;
  background-color: #f3f7f8;
}

.title {
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  text-align: center;
  border-left: 2px solid white;
}

.title h1 {
  font-weight: 700;
  font-size: 2rem;
  color: #ff5f8f;
}

.title p {
  margin-top: 1rem;
  font-size: 1rem;
}

.navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  width: 71rem;
  margin: auto;
  padding-bottom: 1rem;
  align-items: center;
  border-left: 2px solid white;
}

.nav-area {
  width: 100%;
  display: flex;
  justify-content: center;
}

.nav-area ul li {
  list-style: none;
  cursor: pointer;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.nav-area ul li:hover,
.nav-area ul a:hover {
  background-color: #ffdde7;
  transition: 0.2s ease-in-out;
}

.nav-area ul a {
  font-size: 1rem;
  text-decoration: none;
  color: black;
}

.nav-area ul a li h3 {
  font-size: 1rem;
  font-weight: 700;
  color: #ff5f8f;
}

.nav-area p,
.title p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

#show-mobile {
  display: none;
}

hr {
  width: 100%;
  background: #000;
  height: 1px;
  border: none;
}

.image-area {
  width: 100%;
  height: inherit;
  align-content: center;
  display: block;
  margin: auto;
}

.image-area img {
  width: 35rem;
  padding: 3rem;
  align-content: center;
  display: block;
  margin: auto;
}

@media only screen and (max-width: 1400px) {
  .navigation {
    width: 60rem;
    align-items: center;
  }

  .title {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .navigation {
    width: 40rem;
    grid-template-columns: 1fr;
    align-items: center;
    margin-top: 0;
    gap: 0;
  }

  .title {
    width: 40rem;
  }

  .image-area {
    height: inherit;
  }

  .image-area img {
    width: 30rem;
  }

  #show-mobile {
    display: block;
  }

  #hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .navigation {
    width: 30rem;
  }

  .title {
    width: 30rem;
  }

  .title h1 {
    font-size: 1.5rem;
  }

  .image-area img {
    width: 25rem;
  }
}

@media only screen and (max-width: 600px) {
  .navigation {
    width: 90%;
  }

  .nav-area {
    width: 95%;
  }

  .title {
    width: 90%;
  }

  .title h1 {
    font-size: 1.5rem;
  }

  .image-area img {
    width: 85%;
  }

  .title,
  .navigation {
    border-left: none;
  }
}
</style>
