<template>
  <section>
    <div class="clients">
      <div class="first">
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
        <div class="details">
          <img src="/img/maya.svg" alt="" />
          <p><span>Powered by end-to-end digital payments.</span></p>
        </div>
        <div class="details">
          <img src="img/icon-sf-min.png" alt="" />
          <p><span>Customized website in minutes.</span></p>
        </div>
        <div class="details">
          <img src="/img/discord-min.png" alt="" />
          <p><span>The easiest way to talk over voice.</span></p>
        </div>
        <div class="details">
          <img src="/img/gcash-icon.svg" alt="" />
          <p><span>Turn your phone into virtual wallet.</span></p>
        </div>
      </div>

      <div class="second">
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
        <div class="details">
          <img src="img/subscriptions.svg" alt="" />
          <p><span>Recurring subscription payments.</span></p>
        </div>
        <div class="details">
          <img src="/img/grabpay.svg" alt="" />
          <p><span>Cashless payments for Grab services.</span></p>
        </div>
        <div class="details">
          <img src="/img/vod.svg" alt="" />
          <p><span>Upload content on your HelixPay console.</span></p>
        </div>
        <div class="details">
          <img src="img/merch.svg" alt="" />
          <p><span>Offers merch to your fans.</span></p>
        </div>
      </div>

      <div class="third">
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
        <div class="details">
          <img src="/img/notifications.svg" alt="" />
          <p><span>Get notified in seconds!</span></p>
        </div>
        <div class="details">
          <img src="/img/visa.svg" alt="" />
          <p><span>Leader in digital payment technology.</span></p>
        </div>
        <div class="details">
          <img src="/img/analytics.svg" alt="" />
          <p><span>Analytics, understand your customers.</span></p>
        </div>
        <div class="details">
          <img src="/img/dashboard.svg" alt="" />
          <p><span>Manage the data in your own dashboard.</span></p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
span {
  opacity: 0.5;
}

section {
  width: 100%;
}

.clients {
  width: 100%;
  margin: 5rem auto;
  padding: 1rem 0;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 2rem;
  overflow: hidden;
}

.details {
  width: 20rem;
  display: grid;
  grid-template-columns: 25% 65%;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: 0.2s ease-in;
  cursor: pointer;
  gap: 1rem;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  background-color: black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.details:hover {
  transform: scale(0.95);
}

.details img {
  width: 100%;
}

.details p {
  font-weight: 600;
  padding: 0;
  margin: 0;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ FIRST */

.first,
.third {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  gap: 2rem;
  margin: auto;
  animation: left 2000s infinite;
}

@keyframes left {
  0% {
    transform: translateX(-450rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SECOND */

.second {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  gap: 2rem;
  margin: auto;
  animation: right 2000s infinite;
}

@keyframes right {
  0% {
    transform: translateX(0rem);
  }
  100% {
    transform: translateX(-450rem);
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SECOND */

.third {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  gap: 2rem;
  margin: auto;
  animation: left 2000s infinite;
}

@media only screen and (max-width: 750px) {
  .clients {
    gap: 1rem;
  }

  .first,
  .second,
  .third {
    gap: 1rem;
  }

  .details {
    width: 8rem;
    grid-template-columns: 1fr;
  }

  .details img {
    width: 40%;
    margin: auto;
  }

  .details p {
    display: none;
  }

  @keyframes left {
    0% {
      transform: translateX(-220rem);
    }
    100% {
      transform: translateX(0rem);
    }
  }
}
</style>
