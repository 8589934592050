<template>
  <div class="cards">
    <div class="cards-info">
      <section id="container">
        <div class="card-title">
          <div>
            <h1>We are on tiktok!</h1>
            <img
              src="/img/tiktok-logo-for-vid.svg"
              style="width: 10%; pointer-events: none"
              alt=""
            />
          </div>
          <p>
            Browse through content and product ideas, success stories, and more!
          </p>
        </div>

        <div
          v-motion
          class="user-container"
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        >
          <!-- Card One-->
          <div class="card">
            <video
              id="myVideo"
              muted
              loop
              controls
              poster="/img/thoc-thumbnail.jpg"
            >
              <source src="/img/thoc-event.MP4" type="video/mp4" />
            </video>

            <div class="icons">
              <i
                class="fa-brands fa-instagram"
                onclick="window.open('https://www.instagram.com/helixpay.creator/')"
              ></i>
              <i
                class="fa-brands fa-tiktok"
                onclick="window.open('https://www.tiktok.com/@helixpay')"
              ></i>
            </div>
          </div>

          <!-- Card One-->
          <div class="card">
            <video
              id="myVideo"
              muted
              loop
              controls
              poster="/img/tiktok-thumbnail-awkp.jpg"
            >
              <source src="/img/tiktok-awkp.mp4" type="video/mp4" />
            </video>

            <div class="icons">
              <i
                class="fa-brands fa-instagram"
                onclick="window.open('https://www.instagram.com/helixpay.creator/')"
              ></i>
              <i
                class="fa-brands fa-tiktok"
                onclick="window.open('https://www.tiktok.com/@helixpay')"
              ></i>
            </div>
          </div>

          <!-- Card Two-->
          <div class="card">
            <video
              id="myVideo"
              muted
              loop
              controls
              poster="/img/tiktok-thumbnail-christmas.jpg"
            >
              <source src="/img/tiktok-christmas.mp4" type="video/mp4" />
            </video>

            <div class="icons">
              <i
                class="fa-brands fa-instagram"
                onclick="window.open('https://www.instagram.com/helixpay.creator/')"
              ></i>
              <i
                class="fa-brands fa-tiktok"
                onclick="window.open('https://www.tiktok.com/@helixpay')"
              ></i>
            </div>
          </div>

          <!-- Card Three-->
          <div class="card">
            <video
              id="myVideo"
              muted
              loop
              controls
              poster="/img/tiktok-thumbnail-videopodcast.jpg"
            >
              <source src="/img/tiktok-didyouknow.mp4" type="video/mp4" />
            </video>

            <div class="icons">
              <i
                class="fa-brands fa-instagram"
                onclick="window.open('https://www.instagram.com/helixpay.creator/')"
              ></i>
              <i
                class="fa-brands fa-tiktok"
                onclick="window.open('https://www.tiktok.com/@helixpay')"
              ></i>
            </div>
          </div>
        </div>

        <h5 onclick="window.open('https://www.tiktok.com/@helixpay')">
          Follow us on TikTok!<i
            class="fa-brands fa-tiktok"
            style="margin-left: 5px"
          ></i>
        </h5>
      </section>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.cards-info {
  width: 71rem;
  margin: auto;
  border-left: 2px solid white;
}

#container {
  padding: 4rem 2rem;
  max-width: 55rem;
  margin: auto;
  justify-content: center;
}

.tiktok-icon {
  border-radius: 20px;
  color: white;
  background: black;
  padding: 15px;
}

.card-title {
  display: grid;
  gap: 0.5rem;
  text-align: center;
  padding-bottom: 3rem;
}

.card-title div {
  max-width: 40rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}

.card-title h1 {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.5;
}

.card-title p {
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 0.5;
  line-height: 1.5;
}

h5 {
  font-size: 1rem;
  font-weight: 700;
  max-width: 20rem;
  margin: auto;
  text-align: center;
  color: white;
  background-color: black;
  padding: 20px 30px;
  margin-top: 2rem;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.2s ease-in;
}

h5:hover {
  background-color: rgb(26, 26, 26);
}

.user-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(300px, auto);
  gap: 1.5rem;
}

.card {
  gap: 1rem;
  border-radius: 5px;
  background-color: none;
  border: none;
  position: relative;
}

.card video {
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.card h1 {
  font-size: 2rem;
  font-weight: 700;
}

.card p {
  opacity: 0.5;
  font-size: 1rem;
  font-weight: 500;
}

.card:hover .user-content {
  opacity: 1;
}

.icons {
  top: 20px;
  right: 20px;
  display: grid;
  gap: 1rem;
  position: absolute;
}

.icons i {
  font-size: 1.5rem;
  width: 100%;
  color: white;
  cursor: pointer;
  text-align: center;
  background-color: black;
  border-radius: 5px;
  padding: 10px;
  transition: 0.2s ease-in;
}

.icons i:hover {
  color: #d84f78;
}

.user-content {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  position: relative;
}

@media only screen and (max-width: 1400px) {
  .cards-info {
    width: 60rem;
  }
}

@media (max-width: 1050px) {
  .cards-info {
    width: 40rem;
  }

  .card-title h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 1000px) {
  .user-container {
    gap: 2rem;
  }
}

@media (max-width: 800px) {
  #container {
    max-width: 35rem;
    padding: 1rem;
  }

  .user-container {
    grid-template-columns: 1fr;
  }

  .card-title h1 {
    font-size: 1.95rem;
  }

  .card-title p {
    font-size: 1rem;
  }

  h5 {
    max-width: 35rem;
  }

  .card-title div {
    max-width: 20rem;
  }
}

@media (max-width: 650px) {
  .cards-info {
    width: 100%;
    border-left: none;
  }
}
</style>
