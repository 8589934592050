<template>
  <div class="merch">
    <div class="merch-info">
      <div class="social-media-right-part forMobile">
        <img
          v-motion
          src="img/helixpay-map-min.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <div class="social-media-left-part">
        <h1>Here to support you in the Philippines</h1>
        <p class="support-para">
          HelixPay is built and headquartered in Manila. We're here to support
          you to make sure your earnings grow.
        </p>

        <li class="first-list-item">
          <p>
            International quality technology custom built for the Philippines.
          </p>
        </li>
        <li class="first-list-item">
          <p>
            Sales and support teams based in Manila speaking fluent English,
            Tagalog and Cebuano.
          </p>
        </li>
        <li class="first-list-item">
          <p>
            Available to help you during Manila office hours (and nights and
            weekends!).
          </p>
        </li>
      </div>

      <div class="social-media-right-part forWeb">
        <img
          v-motion
          src="img/helixpay-map-min.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none;
}

.merch {
  width: 100%;
  background-color: #f3f7f8;
}

.merch-info {
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  border-left: 2px solid white;
}

.social-media-left-part h1 {
  line-height: 1.2;
  font-size: 4rem;
  font-weight: 800;
  margin-left: -2px;
  padding-left: 1.45rem;
  border-left: 2px solid #ff5f8f;
}

.social-media-left-part li {
  width: 100%;
  align-items: center;
  font-size: 1rem;
  top: -10rem;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-left: 1.45rem;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list-cc.svg');
  background-repeat: no-repeat;
  background-position: 0% 20%;
  background-size: 1rem;
}

.support-para {
  width: 100%;
  padding-left: 30px;
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 2rem;
}

.social-media-left-part li p {
  line-height: 1.5;
  font-weight: 500;
  opacity: 0.6;
}

.social-media-right-part {
  width: 100%;
  margin: auto;
}

.social-media-right-part img {
  width: 70%;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .merch-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .merch-info {
    width: 40rem;
    grid-template-columns: 1fr;
  }

  .social-media-right-part img {
    width: 90%;
    margin: auto;
    display: block;
  }

  .support-para {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .merch-info {
    gap: 1rem;
    width: 90%;
    border-left: none;
  }

  .social-media-left-part h1 {
    font-size: 2rem;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-logos {
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-right-part {
    width: 100%;
  }

  .social-media-right-part img {
    width: 60%;
  }

  .social-media-left-part li {
    margin-left: 0;
  }

  .support-para {
    font-size: 1rem;
    top: -10rem;
    margin-bottom: 20px;
    margin-left: 0;
    padding-left: 0;
  }
}
</style>
