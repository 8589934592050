<template>
  <div class="intro-vid">
    <div
      v-motion
      class="intro-vid-info"
      :initial="{
        opacity: 0,
        y: 100,
      }"
      :visibleOnce="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          stiffness: 200,
          damping: 90,
          mass: 3,
        },
      }"
    >
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Py6gJYK_kTA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<style scoped>
.intro-vid {
  width: 100%;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 5rem;
  background-color: white;
}

.intro-vid-info {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 71rem;
  margin: auto;
}

iframe {
  display: block;
  width: 60%;
  height: 400px;
  margin: auto;
  box-shadow: 0px 0px 10px rgb(88, 88, 88, 0.3);
}

@media only screen and (max-width: 1400px) {
  iframe {
    width: 70%;
    height: 400px;
  }

  .intro-vid-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  iframe {
    width: 100%;
    height: 400px;
  }

  .intro-vid-info {
    gap: 3rem;
    width: 40rem;
  }
}

@media only screen and (max-width: 650px) {
  iframe {
    width: 100%;
    height: 400px;
  }

  .intro-vid-info {
    width: 90%;
  }
}
</style>
